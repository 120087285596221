<template>
  <v-card flat :loading="disabled" :disabled="disabled">
    <v-card-text class="mt-1 pt-0">
      <h3 id="cast_sync_step3">取込の確認</h3>
      <v-subheader
        >下記のセラピストを処理します。選択に誤りがある場合は、ひとつ前の画面で修正して下さい。</v-subheader
      >
      <v-container>
        <v-row>
          <v-col cols="12">
            <!-- テーブル -->
            <v-data-table
              class="sync_table"
              :headers="headers"
              :items="desserts"
              :mobile-breakpoint="0"
              item-key="primary_key"
              :footer-props="{ 'items-per-page-options': [-1] }"
              hide-default-footer
            >
              <template v-slot:no-data> 表示するデータがありません </template>
              <template v-slot:item.name="{ item }"> <!-- eslint-disable-line -->
                <template
                  ><v-avatar class="ma-1 mr-3"
                    ><v-img :src="setting.cdn + item.image" /></v-avatar
                  >{{ item.display_label }}</template
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="3">
            <general-button btn_type="gray" btn_block @click-event="onCancel"
              >キャンセル</general-button
            >
          </v-col>
          <v-col cols="6" sm="3"> </v-col>
          <v-col cols="6" sm="3">
            <general-button btn_type="gray" btn_block @click-event="onBack"
              >戻る</general-button
            >
          </v-col>
          <v-col cols="6" sm="3">
            <general-button
              btn_type="info"
              btn_block
              :btn_disabled="desserts.length == 0"
              @click-event="onSubmit"
              >取込処理実行</general-button
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { reactive, toRefs, defineComponent, set } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import ModelShopSite from "@/model/shopSite.js";

export default defineComponent({
  setup(_, ctx) {
    const SynchroRepository = repositoryFactory.get("synchro");
    const state = reactive({
      shopSiteId: 0,
      siteOnlyItems: [],
      disabled: false,
      headers: [
        {
          text: "媒体に登録してあるセラピスト",
          value: "name",
          sortable: true,
        },
        {
          text: "取込内容",
          value: "status",
          sortable: true,
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      state.disabled = true;

      state.shopSiteId = store.getters["synchro_cast/getSelectedShopSiteId"];
      state.siteOnlyItems = store.getters["synchro_cast/getSiteOnlyItems"];

      state.desserts.splice(0);
      const tmp = store.getters["synchro_cast/getSelectedCastList"];
      state.desserts = tmp.sort((first, second) => first - second);

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.disabled = false;
    };

    // キャスト同期処理
    const castSync = async () => {
      // ローディング表示
      state.disabled = true;
      store.dispatch("loadingIcon/showIcon");

      let result = false;
      const params = {
        shop_site_id: state.shopSiteId,
        site_only_items: state.siteOnlyItems,
        cast_list: state.desserts,
      };
      result = await SynchroRepository.sync_update_cast(params)
        .then((response) => {
          if (response.data) {
            if (response.data.status) {
              return true;
            } else {
              alert(response.data.message);
              return false;
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:Step3.vue/castSync SynchroRepository.sync_update_cast (" +
            error +
            ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.disabled = false;

      return result;
    };

    const onCancel = () => {
      ctx.emit("click-cancel");
    };
    const onBack = () => {
      ctx.emit("click-back");
    };
    const onSubmit = async () => {
      // キャスト取り込み処理
      if (await castSync()) {
        // 更新が完了したらキャスト&店舗サイトを取り直す
        await ModelShopSite.setStore();
        // await ModelCast.setStore();
        ctx.emit("sync-ok");
      }
    };

    return {
      setting,
      ...toRefs(state),
      init,
      onSubmit,
      onCancel,
      onBack,
    };
  },
});
</script>
