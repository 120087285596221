<template>
  <v-card flat :loading="disabled" :disabled="disabled">
    <v-card-text class="mt-1 pt-0">
      <h3 id="cast_sync_step1">取込媒体選択</h3>
      <v-subheader
        >セラピスト情報を取り込む媒体を選択してください。</v-subheader
      >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              prepend-icon="mdi-web"
              v-model="shopSiteId"
              :items="shopSites"
              item-value="id"
              item-text="name"
              no-data-text="更新管理にて媒体を登録して下さい"
              label="媒体選択"
              hide-details
              success
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <h3 class="mt-6">媒体個別取り込み</h3>
      <p class="pl-4 pr-4">
        <span class="text-red"
          >全媒体で写真やプロフィールが同じ場合はチェックを入れないでください。</span
        ><br />下記にチェックを入れると媒体専用情報として取り込みを行います。媒体毎に写真やプロフィールが違う場合にご利用ください。
      </p>
      <v-container class="mb-6">
        <v-row>
          <v-col cols="12">
            <v-checkbox
              class="mt-0"
              v-model="siteOnlyItems"
              label="お店コメント・セラピストコメント"
              value="comment"
            ></v-checkbox>
            <v-checkbox
              v-model="siteOnlyItems"
              class="mt-0"
              label="セラピスト画像"
              value="image"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="6" sm="3">
            <general-button btn_type="gray" btn_block @click-event="onCancel"
              >キャンセル</general-button
            >
          </v-col>
          <v-col cols="6" sm="3"> </v-col>
          <v-col cols="6" sm="3">
            <general-button
              btn_type="gray"
              btn_block
              btn_disabled
              @click-event="onBack"
              >戻る</general-button
            >
          </v-col>
          <v-col cols="6" sm="3">
            <general-button btn_type="info" btn_block @click-event="onNext"
              >次へ</general-button
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";

export default defineComponent({
  setup(_, ctx) {
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const state = reactive({
      shopSites: [],
      shopSiteId: 0,
      defaultShopSiteId: 0,
      disabled: false,
      siteOnlyItems: [],
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      // 更新サイト一覧取得
      state.shopSites.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getcast(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.shopSites.push({
                "id": response.data[key].id,
                "name":
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
              });
            });
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });
      state.shopSiteId = await store.getters["shop_sites/getDefaultShopSiteId"];
      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 初期化する
    onMounted(async () => {
      await init();
    });

    const onCancel = () => {
      ctx.emit("click-cancel");
    };
    const onBack = () => {
      ctx.emit("click-back");
    };
    const onNext = () => {
      // vuexに、選択されたshop_site_idとsite_idを記憶する
      store.dispatch("synchro_cast/setShopSiteId", state.shopSiteId);
      const site = state.shopSites.find(
        (value) => value.id == state.shopSiteId
      );
      store.dispatch("synchro_cast/setSiteId", site.site_id);
      store.dispatch("synchro_cast/setSiteOnlyItems", state.siteOnlyItems);
      ctx.emit("click-next");
    };

    return {
      ...toRefs(state),
      onCancel,
      onNext,
      onBack,
    };
  },
});
</script>
