<template>
  <v-card flat :loading="disabled" :disabled="disabled">
    <v-card-text class="mt-1 pt-0">
      <h3 id="cast_sync_step3">取込セラピスト選択</h3>
      <v-subheader
        >下記のセラピストが見つかりました。取り込みたいセラピストを選択してください。</v-subheader
      >
      <v-container>
        <v-row>
          <v-col cols="12">
            <!-- テーブル -->
            <v-data-table
              class="sync_table"
              :headers="headers"
              :items="desserts"
              :mobile-breakpoint="0"
              item-key="primary_key"
              show-select
              :single-select="false"
              v-model="selected"
              :footer-props="{ 'items-per-page-options': [-1] }"
              hide-default-footer
              @click:row="rowClick"
            >
              <template v-slot:no-data> 表示するデータがありません </template>
              <template v-slot:item.name="{ item }"> <!-- eslint-disable-line -->
                <div>
                  <v-avatar class="ma-1 mr-3"
                    ><v-img :src="setting.cdn + item.image" /></v-avatar
                  >{{ item.display_label }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="3">
            <general-button btn_type="gray" btn_block @click-event="onCancel"
              >キャンセル</general-button
            >
          </v-col>
          <v-col cols="6" sm="3"> </v-col>
          <v-col cols="6" sm="3">
            <general-button btn_type="gray" btn_block @click-event="onBack"
              >戻る</general-button
            >
          </v-col>
          <v-col cols="6" sm="3">
            <general-button btn_type="info" btn_block @click-event="onNext"
              >次へ</general-button
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { reactive, toRefs, defineComponent, set } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";

export default defineComponent({
  setup(_, ctx) {
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const SynchroRepository = repositoryFactory.get("synchro");
    const castsRepository = repositoryFactory.get("casts");

    const state = reactive({
      shopSites: [],
      shopSiteId: 0,
      disabled: false,
      shopSiteCasts: [],
      casts: [],
      syncCastsList: [],
      headers: [
        {
          text: "媒体に登録してあるセラピスト",
          value: "name",
          sortable: true,
        },
        {
          text: "取込内容",
          value: "status",
          sortable: true,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      selected: [],
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      state.disabled = true;

      // 登録済キャスト取得
      state.casts.splice(0);
      let params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await castsRepository
        .list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.casts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:cast/synchroCast/step2.vue/init castsRepository.list (" +
            error +
            ")"
          );
        });

      // 媒体からキャスト一覧を取得
      state.shopSiteId = store.getters["synchro_cast/getSelectedShopSiteId"];
      await ShopSitesRepository.get(state.shopSiteId).then((response) => {
        if (response.data) {
          state.shopSites = response.data;
        }
      });
      // 既にリスト取得済の場合は取得処理を飛ばす
      params = {
        shop_id: store.getters["shops/currentShop"].id,
        site_id: state.shopSites.site_id,
        auth_url: state.shopSites.auth_url,
        loginid: state.shopSites.loginid,
        password: state.shopSites.password,
        shop_site_id: state.shopSites.id,
        proxy_server: state.shopSites.proxy_server,
      };
      // キャスト一覧取得(媒体から取込)
      state.desserts.splice(0);
      await SynchroRepository.sync_cast_simple(params)
        .then((response) => {
          if (response.data) {
            if (response.data.status) {
              const cast_data = response.data.data;
              let order_num = 0;
              Object.keys(cast_data).forEach(function (key) {
                const cast_status = state.casts.find(
                  (val) => val.name == cast_data[key].name
                );
                state.desserts.push({
                  id: 0, // ここでは0固定
                  shop_id: store.getters["shops/currentShop"].id,
                  name: cast_data[key].name,
                  status: !cast_status ? "新規登録" : "更新",
                  primary_key: cast_data[key].primary_key,
                  display_label:
                    cast_data[key].name +
                    (cast_data[key].is_enable ? "" : " [非公開中]"),
                  image: cast_data[key].image,
                  is_enable: cast_data[key].is_enable,
                  is_active: cast_data[key].is_active,
                  list_order: order_num,
                });
                order_num++;
              });
            } else {
              alert(response.data.message);
            }
          }
        })
        .catch((error) => {
          throw "ERROR:Step2.vue/init ShopSitesRepository.list (" + error + ")";
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.disabled = false;
    };

    const onCancel = () => {
      ctx.emit("click-cancel");
    };
    const onBack = () => {
      ctx.emit("click-back");
    };
    const onNext = () => {
      store.dispatch("synchro_cast/clearSelectedCastList");
      store.dispatch("synchro_cast/setSelectedCastList", state.selected);
      ctx.emit("click-next");
    };
    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    return {
      setting,
      ...toRefs(state),
      init,
      onCancel,
      onBack,
      onNext,
      rowClick,
    };
  },
});
</script>

<style scoped>
.sync_table .select_item {
  margin-top: 8px;
  display: inline-block;
}
.sync_table .div_mode_update .v-input--selection-controls {
  margin-top: 4px;
  margin-bottom: 8px;
}
.sync_table .div_mode_new .v-input--selection-controls {
  margin-top: 12px;
}
::v-deep .sync_table .v-messages {
  min-height: 0px !important;
}
::v-deep .sync_table .v-text-field__details,
::v-deep .sync_table .v-input__slot {
  min-height: 0px !important;
  margin-bottom: 0px !important;
}
</style>
