<template>
  <div>
    <div>
      <v-app-bar flat color="white" class="x-scroll">
        <div class="pa-2">
          <v-btn class="info font-weight-bold" @click="onNew"
            ><v-icon left>mdi-plus-box</v-icon>新規登録</v-btn
          >
        </div>
        <div class="pa-2">
          <v-btn
            class="info font-weight-bold"
            :disabled="false"
            @click="onUpdateButton"
            ><v-icon left>mdi-sync</v-icon>一括更新</v-btn
          >
        </div>
        <div class="pa-2">
          <v-btn
            class="info font-weight-bold"
            :disabled="false"
            @click="onSortButton"
            ><v-icon left>mdi-sort-alphabetical-ascending</v-icon
            >並び替え</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex justify-end">
          <synchro-cast-component
            @reset="reset"
            :key="comResetKey"
          ></synchro-cast-component>
        </div>
      </v-app-bar>
    </div>
    <div>
      <list-table-component :key="resetKey"></list-table-component>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";
import { useRouter } from "@/utils";
import SynchroCastComponent from "./synchroCast";
import ListTableComponent from "./listTable";
import store from "@/store/index.js";
import utilFunc from "@/common/utilFunc.js";

export default {
  components: {
    SynchroCastComponent,
    ListTableComponent,
  },
  setup(props, ctx) {
    const { router } = useRouter();
    const resetKey = ref(0);
    const comResetKey = ref(0);
    const fab = ref(0);

    const reset = () => {
      resetKey.value++;
      comResetKey.value++;
    };
    const state = reactive({
      rankingDeliMessageFlg: false,
    });

    const onNew = async () => {
      router.push({ name: "cast-edit", params: { cast_id: "new" } });
    };

    const onUpdateButton = async () => {
      router.push({ name: "cast-update" });
    };

    const onSortButton = async () => {
      router.push({ name: "cast-sort" });
    };

    const init = async () => {
      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;
    };

    init();

    return {
      ...toRefs(state),
      resetKey,
      comResetKey,
      reset,
      fab,
      onNew,
      onUpdateButton,
      onSortButton,
    };
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 100;
}
</style>
